import { combineReducers } from 'redux';
import { SET_DEFAULT_PRICE_RULE } from '../actions/external';

const defaultPriceRule = (state = null, action) => {
	switch (action.type) {
		case SET_DEFAULT_PRICE_RULE:
			return action.payload;
		default:
			return state;
	}
};

export default combineReducers({
	defaultPriceRule
});

export const getDefaultPriceRuleId = state => state.defaultPriceRule;
