import { LoadingScreen } from '@segunosoftware/equinox';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAccount } from '../reducers';
import AccountContainer from './AccountContainer';

ProtectedRoute.propTypes = {
	element: PropTypes.any.isRequired,
	account: PropTypes.object
};

function ProtectedRoute({ account, element, ...rest }) {
	return <AccountContainer>{account ? element : <LoadingScreen />}</AccountContainer>;
}

const mapStateToProps = state => ({
	account: getAccount(state)
});

export default connect(mapStateToProps)(ProtectedRoute);
