import { LoadingScreen, useAppBridgeRouter } from '@segunosoftware/equinox';
import { Provider, useAppBridge } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import React, { Suspense, useMemo } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import CustomLinkComponent from './CustomLinkComponent';
import ProtectedRoute from './containers/ProtectedRoute';
import SentryWrapperContainer from './containers/SentryWrapperContainer';
import { useHostParam } from './hooks/useHostParam';
import apiMiddleware from './middleware/api';
import rootReducer from './reducers';
import { ExternalContainer, OAuth, SettingsContainer } from './utils/lazy-load';

const SHOPIFY_APP_KEY = import.meta.env.VITE_SHOPIFY_KEY;

export default function App() {
	const location = useLocation();
	const host = useHostParam() ?? '';
	const router = useAppBridgeRouter();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	const config = useMemo(
		() => ({
			apiKey: SHOPIFY_APP_KEY,
			host,
			forceRedirect: !isOAuthPath
		}),
		[host, isOAuthPath]
	);
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<Provider config={config} router={router}>
				<ReduxApp />
			</Provider>
		</AppProvider>
	);
}

const ReduxApp = React.memo(() => {
	const app = useAppBridge();
	const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

	const middleware = [apiMiddleware(app, apiEndpoint), thunk];
	if (!import.meta.env.PROD) {
		middleware.push(logger);
	}
	const composeEnhancers = composeWithDevTools({});
	const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

	return (
		<ReduxProvider store={store}>
			<SentryWrapperContainer>
				<Suspense fallback={<LoadingScreen delay={500} />}>
					<Routes>
						<Route path="/oauth/*" element={<OAuth />} />
						<Route path="/external/*" element={<ExternalContainer />} />
						<Route path="/*" element={<ProtectedRoute element={<SettingsContainer />} />} />
					</Routes>
				</Suspense>
			</SentryWrapperContainer>
		</ReduxProvider>
	);
});
