import { callApi } from '../middleware/api';

export const SET_REVIEW_SENTIMENT = 'account/SET_REVIEW_SENTIMENT';
export const SET_REVIEW_SENTIMENT_SUCCESS = 'account/SET_REVIEW_SENTIMENT_SUCCESS';
export const SET_REVIEW_SENTIMENT_ERROR = 'account/SET_REVIEW_SENTIMENT_ERROR';

export const SET_REVIEW_STATUS = 'account/SET_REVIEW_STATUS';
export const SET_REVIEW_STATUS_SUCCESS = 'account/SET_REVIEW_STATUS_SUCCESS';
export const SET_REVIEW_STATUS_ERROR = 'account/SET_REVIEW_STATUS_ERROR';

export const SET_DISPLAY_AD_STATUS = 'account/SET_DISPLAY_AD_STATUS';
export const SET_DISPLAY_AD_STATUS_SUCCESS = 'account/SET_DISPLAY_AD_STATUS_SUCCESS';
export const SET_DISPLAY_AD_STATUS_ERROR = 'account/SET_DISPLAY_AD_STATUS_ERROR';

const setReviewSentimentAction = reviewSentiment => ({
	type: SET_REVIEW_SENTIMENT,
	payload: reviewSentiment
});

const setReviewSentimentSuccessAction = reviewSentiment => ({
	type: SET_REVIEW_SENTIMENT_SUCCESS,
	payload: reviewSentiment
});

const setReviewSentimentErrorAction = reviewSentiment => ({
	type: SET_REVIEW_SENTIMENT_ERROR,
	payload: reviewSentiment
});

export const setReviewSentiment = reviewSentiment =>
	callApi('/account/review-sentiment', {
		before: setReviewSentimentAction(reviewSentiment),
		success: setReviewSentimentSuccessAction(reviewSentiment),
		error: setReviewSentimentErrorAction(reviewSentiment),
		config: {
			method: 'POST',
			body: {
				reviewSentiment
			}
		}
	});

const setReviewStatusAction = reviewStatus => ({
	type: SET_REVIEW_STATUS,
	payload: reviewStatus
});

const setReviewStatusSuccessAction = reviewStatus => ({
	type: SET_REVIEW_STATUS_SUCCESS,
	payload: reviewStatus
});

const setReviewStatusErrorAction = reviewStatus => ({
	type: SET_REVIEW_STATUS_ERROR,
	payload: reviewStatus
});

export const setReviewStatus = reviewStatus =>
	callApi('/account/review-status', {
		before: setReviewStatusAction(reviewStatus),
		success: setReviewStatusSuccessAction(reviewStatus),
		error: setReviewStatusErrorAction(reviewStatus),
		config: {
			method: 'POST',
			body: {
				reviewStatus
			}
		}
	});

const setDisplayAdStatusAction = displayAdStatus => ({
	type: SET_DISPLAY_AD_STATUS,
	payload: displayAdStatus
});

const setDisplayAdStatusSuccessAction = displayAdStatus => ({
	type: SET_DISPLAY_AD_STATUS_SUCCESS,
	payload: displayAdStatus
});

const setDisplayAdStatusErrorAction = displayAdStatus => ({
	type: SET_DISPLAY_AD_STATUS_ERROR,
	payload: displayAdStatus
});

export const setDisplayAdStatus = displayAdStatus =>
	callApi('/account/display-ad-status', {
		before: setDisplayAdStatusAction(displayAdStatus),
		success: setDisplayAdStatusSuccessAction(displayAdStatus),
		error: setDisplayAdStatusErrorAction(displayAdStatus),
		config: {
			method: 'POST',
			body: {
				displayAdStatus
			}
		}
	});
