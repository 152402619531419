import { combineReducers } from 'redux';
import app, * as fromApp from './app';
import auth, * as fromAuth from './auth';
import bannerConfiguration, * as fromBannerConfiguration from './bannerConfiguration';
import external, * as fromExternal from './external';
import priceRules, * as fromPriceRules from './priceRules';

export default combineReducers({
	app,
	auth,
	bannerConfiguration,
	priceRules,
	external
});

export const getAppError = state => fromApp.getAppError(state.app);
export const isLoading = state => isBannerConfigurationLoading(state) || isBannerConfigurationSaving(state) || isPriceRuleLoading(state);

export const isPerformingWhoAmI = state => fromAuth.isPerformingWhoAmI(state.auth);
export const getLoginFailureMessage = state => fromAuth.getLoginFailureMessage(state.auth);
export const getAccount = state => fromAuth.getAccount(state.auth);

export const getBannerConfiguration = state => fromBannerConfiguration.getBannerConfiguration(state.bannerConfiguration);
export const isBannerConfigurationLoading = state => fromBannerConfiguration.isBannerConfigurationLoading(state.bannerConfiguration);
export const isBannerConfigurationSaving = state => fromBannerConfiguration.isBannerConfigurationSaving(state.bannerConfiguration);

export const getPriceRule = (state, code) => fromPriceRules.getPriceRule(state.priceRules, code);
export const getPriceRuleById = (state, id) => fromPriceRules.getPriceRuleById(state.priceRules, id);
export const getPriceRuleError = (state, code) => fromPriceRules.getPriceRuleError(state.priceRules, code);
export const isPriceRuleLoading = (state, code) => fromPriceRules.isPriceRuleLoading(state.priceRules, code);
export const isPriceRuleLoadingById = (state, id) => fromPriceRules.isPriceRuleLoadingById(state.priceRules, id);

export const getDefaultPriceRuleId = state => fromExternal.getDefaultPriceRuleId(state.external);
