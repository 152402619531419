import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAccount, isPerformingWhoAmI } from '../reducers';
import { loadAccount } from '../actions/auth';

AccountContainer.propTypes = {
	onLoadAccount: PropTypes.func.isRequired,
	isAccountLoading: PropTypes.bool.isRequired,
	account: PropTypes.object
};

function AccountContainer({ account, isAccountLoading, onLoadAccount, children }) {
	useEffect(() => {
		if (!account && !isAccountLoading) {
			onLoadAccount();
		}
	}, [account, isAccountLoading, onLoadAccount]);

	return children;
}

const mapStateToProps = state => ({
	account: getAccount(state),
	isAccountLoading: isPerformingWhoAmI(state)
});

const mapDispatchToProps = {
	onLoadAccount: loadAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
