import { combineReducers } from 'redux';
import {
	LOAD_BANNER_CONFIGURATION,
	LOAD_BANNER_CONFIGURATION_SUCCESS,
	LOAD_BANNER_CONFIGURATION_ERROR,
	SAVE_BANNER_CONFIGURATION,
	SAVE_BANNER_CONFIGURATION_SUCCESS,
	SAVE_BANNER_CONFIGURATION_ERROR
} from '../actions/bannerConfiguration';

const configuration = (state = null, action) => {
	switch (action.type) {
		case LOAD_BANNER_CONFIGURATION_SUCCESS:
		case SAVE_BANNER_CONFIGURATION_SUCCESS:
			const configuration = { ...action.payload };
			if (configuration.customStartsAt) {
				configuration.customStartsAt = new Date(configuration.customStartsAt);
			}
			if (configuration.customEndsAt) {
				configuration.customEndsAt = new Date(configuration.customEndsAt);
			}
			if (configuration.createdAt) {
				configuration.createdAt = new Date(configuration.createdAt);
			}
			if (configuration.updatedAt) {
				configuration.updatedAt = new Date(configuration.updatedAt);
			}
			if (configuration.firstDeployedAt) {
				configuration.firstDeployedAt = new Date(configuration.firstDeployedAt);
			}
			return configuration;
		default:
			return state;
	}
};

const isLoading = (state = false, action) => {
	switch (action.type) {
		case LOAD_BANNER_CONFIGURATION:
			return true;
		case LOAD_BANNER_CONFIGURATION_SUCCESS:
		case LOAD_BANNER_CONFIGURATION_ERROR:
			return false;
		default:
			return state;
	}
};

const isSaving = (state = false, action) => {
	switch (action.type) {
		case SAVE_BANNER_CONFIGURATION:
			return true;
		case SAVE_BANNER_CONFIGURATION_SUCCESS:
		case SAVE_BANNER_CONFIGURATION_ERROR:
			return false;
		default:
			return state;
	}
};

export default combineReducers({
	configuration,
	isLoading,
	isSaving
});

export const getBannerConfiguration = state => state.configuration;
export const isBannerConfigurationLoading = state => state.isLoading;
export const isBannerConfigurationSaving = state => state.isSaving;
