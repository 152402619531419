import { callApi } from '../middleware/api';

export const LOAD_BANNER_CONFIGURATION = 'bannerConfiguration/LOAD_BANNER_CONFIGURATION';
export const LOAD_BANNER_CONFIGURATION_SUCCESS = 'bannerConfiguration/LOAD_BANNER_CONFIGURATION_SUCCESS';
export const LOAD_BANNER_CONFIGURATION_ERROR = 'bannerConfiguration/LOAD_BANNER_CONFIGURATION_ERROR';

export const SAVE_BANNER_CONFIGURATION = 'bannerConfiguration/SAVE_BANNER_CONFIGURATION';
export const SAVE_BANNER_CONFIGURATION_SUCCESS = 'bannerConfiguration/SAVE_BANNER_CONFIGURATION_SUCCESS';
export const SAVE_BANNER_CONFIGURATION_ERROR = 'bannerConfiguration/SAVE_BANNER_CONFIGURATION_ERROR';

export const loadBannerConfiguration = () =>
	callApi('/banner-configuration', {
		before: LOAD_BANNER_CONFIGURATION,
		success: LOAD_BANNER_CONFIGURATION_SUCCESS,
		error: LOAD_BANNER_CONFIGURATION_ERROR
	});

const saveBannerAction = configuration => ({
	type: SAVE_BANNER_CONFIGURATION,
	payload: configuration
});

export const saveBannerConfiguration = configuration =>
	callApi('/banner-configuration', {
		before: saveBannerAction(configuration),
		success: SAVE_BANNER_CONFIGURATION_SUCCESS,
		error: SAVE_BANNER_CONFIGURATION_ERROR,
		config: {
			method: 'PUT',
			body: configuration
		}
	});
