import { callApi } from '../middleware/api';

export const GET_PRICE_RULE = 'priceRules/GET_PRICE_RULE';
export const GET_PRICE_RULE_SUCCESS = 'priceRules/GET_PRICE_RULE_SUCCESS';
export const GET_PRICE_RULE_ERROR = 'priceRules/GET_PRICE_RULE_ERROR';

export const LOAD_PRICE_RULE_BY_ID = 'priceRules/LOAD_PRICE_RULE_BY_ID';
export const LOAD_PRICE_RULE_BY_ID_SUCCESS = 'priceRules/LOAD_PRICE_RULE_BY_ID_SUCCESS';
export const LOAD_PRICE_RULE_BY_ID_ERROR = 'priceRules/LOAD_PRICE_RULE_BY_ID_ERROR';

const loadPriceRuleAction = code => ({
	type: GET_PRICE_RULE,
	payload: code
});

const loadPriceRuleErrorAction = code => ({
	type: GET_PRICE_RULE_ERROR,
	payload: code
});

export const loadPriceRule = (code, cdnCache = false) =>
	callApi(`/price-rules/${encodeURIComponent(code)}?cdnCache=${cdnCache}`, {
		before: loadPriceRuleAction(code),
		success: GET_PRICE_RULE_SUCCESS,
		error: loadPriceRuleErrorAction(code)
	});

const loadPriceRuleByIdAction = id => ({
	type: LOAD_PRICE_RULE_BY_ID,
	payload: id
});

const loadPriceRuleByIdErrorAction = id => ({
	type: LOAD_PRICE_RULE_BY_ID_ERROR,
	payload: id
});

export const loadPriceRuleById = id =>
	callApi(`/price-rules/id/${id}`, {
		before: loadPriceRuleByIdAction(id),
		success: LOAD_PRICE_RULE_BY_ID_SUCCESS,
		error: loadPriceRuleByIdErrorAction(id)
	});
