import { combineReducers } from 'redux';
import {
	GET_PRICE_RULE,
	GET_PRICE_RULE_SUCCESS,
	GET_PRICE_RULE_ERROR,
	LOAD_PRICE_RULE_BY_ID,
	LOAD_PRICE_RULE_BY_ID_SUCCESS,
	LOAD_PRICE_RULE_BY_ID_ERROR
} from '../actions/priceRules';

const priceRules = (state = {}, action) => {
	switch (action.type) {
		case GET_PRICE_RULE:
			const getState = { ...state };
			delete getState[action.payload];
			return getState;
		case GET_PRICE_RULE_SUCCESS:
		case LOAD_PRICE_RULE_BY_ID_SUCCESS:
			if (!action.payload.priceRuleConfiguration) {
				return state;
			}
			const config = { ...action.payload.priceRuleConfiguration, code: action.payload.code };
			if (config.startsAt) {
				config.startsAt = new Date(config.startsAt);
			}
			if (config.endsAt) {
				config.endsAt = new Date(config.endsAt);
			}
			return { ...state, [config.code.toLowerCase()]: { id: action.payload.priceRuleId, priceRule: config } };
		default:
			return state;
	}
};

const errors = (state = {}, action) => {
	switch (action.type) {
		case GET_PRICE_RULE:
			return { ...state, [action.payload]: null };
		case GET_PRICE_RULE_SUCCESS:
		case LOAD_PRICE_RULE_BY_ID_SUCCESS:
			const checkForErrors = () => {
				if (!action.payload.priceRuleConfiguration) {
					return 'This discount was not found in Shopify.';
				}
				const now = new Date();
				if (
					action.payload.priceRuleConfiguration.endsAt &&
					new Date(action.payload.priceRuleConfiguration.endsAt).getTime() <= now.getTime()
				) {
					return 'This discount has expired.';
				}
				return null;
			};
			return { ...state, [action.payload.code]: checkForErrors() };
		case GET_PRICE_RULE_ERROR:
			return { ...state, [action.payload]: action.payload.message };
		default:
			return state;
	}
};

const isLoading = (state = {}, action) => {
	switch (action.type) {
		case GET_PRICE_RULE:
		case LOAD_PRICE_RULE_BY_ID:
			return { ...state, [action.payload]: true };
		case GET_PRICE_RULE_SUCCESS:
		case GET_PRICE_RULE_ERROR:
		case LOAD_PRICE_RULE_BY_ID_SUCCESS:
		case LOAD_PRICE_RULE_BY_ID_ERROR:
			return { ...state, [action.payload.code]: false };
		default:
			return state;
	}
};

const isLoadingById = (state = {}, action) => {
	switch (action.type) {
		case LOAD_PRICE_RULE_BY_ID:
			return { ...state, [action.payload]: true };
		case LOAD_PRICE_RULE_BY_ID_ERROR:
		case LOAD_PRICE_RULE_BY_ID_SUCCESS:
			return { ...state, [action.payload]: false };
		default:
			return state;
	}
};

export default combineReducers({
	priceRules,
	errors,
	isLoading,
	isLoadingById
});

export const getPriceRule = (state, code) => code && state.priceRules[code.toLowerCase()] && state.priceRules[code.toLowerCase()].priceRule;
export const getPriceRuleById = (state, id) =>
	getPriceRule(
		state,
		Object.keys(state.priceRules).find(key => state.priceRules[key].id === Number(id))
	);
export const getPriceRuleError = (state, code) => state.errors[code];
export const isPriceRuleLoading = (state, code) => state.isLoading[code] === true;
export const isPriceRuleLoadingById = (state, id) => state.isLoadingById[id] === true;
