import { getSessionToken } from '@shopify/app-bridge/utilities';
import { appError } from '../actions/app';

const API_ACTION = Symbol('Seguno Banner API');

const prepareConfig = (config = {}, accessToken) => {
	config.mode = 'cors';
	config.method = config.method || 'GET';
	const headers = config.headers || {};
	config.headers = headers;
	if (accessToken) {
		headers['Authorization'] = `Bearer ${accessToken}`;
	}
	headers['Accept'] = headers['Accept'] || 'application/json';
	if (typeof config['body'] !== 'undefined') {
		if (!(config['body'] instanceof FormData)) {
			headers['Content-Type'] = headers['Content-Type'] || 'application/json';
		}
		if (headers['Content-Type'] === 'application/json' && typeof config['body'] !== 'string') {
			config['body'] = JSON.stringify(config['body']);
		}
	}
	return config;
};

const parseJson = text => {
	try {
		return JSON.parse(text);
	} catch (e) {
		return {};
	}
};

const api = (url, config, accessToken) =>
	fetch(url, prepareConfig(config, accessToken)).then(
		response =>
			response.text().then(text => {
				if (!response.ok && response.status === 500) {
					throw new Error(`${response.status} ${response.statusText}: ${text}`);
				}
				return { json: parseJson(text), response };
			}),
		err => {
			throw err;
		}
	);

export const callApi = (url, config) => ({
	[API_ACTION]: {
		url,
		...config
	}
});

const findAccessToken = (app, unprotected = false) => {
	if (unprotected) {
		return Promise.resolve();
	}
	return getSessionToken(app);
};

const getActionResponse = (resp, json = {}) => {
	const respAction = typeof resp !== 'string' ? resp : { type: resp, payload: json };
	if (typeof respAction === 'function') {
		return respAction(json);
	}
	return respAction;
};

const apiMiddleware =
	(app, baseUrl = '') =>
	store =>
	next =>
	action => {
		if (typeof action[API_ACTION] === 'undefined') {
			return next(action);
		}
		const { unprotected, url, config, before, success, error } = action[API_ACTION];
		return findAccessToken(app, unprotected).then(accessToken => {
			const actualUrl = url === null || url === '' || url.indexOf('/') === 0 ? baseUrl + url : url;
			if (before) {
				const beforeAction = typeof before !== 'string' ? before : { type: before };
				next(beforeAction);
			}
			return api(actualUrl, config, accessToken)
				.then(({ json, response }) => next(getActionResponse(response.ok ? success : error, json)))
				.catch(err => next(appError(err)));
		});
	};

export default apiMiddleware;
