import { combineReducers } from 'redux';
import { WHOAMI, WHOAMI_SUCCESS, WHOAMI_ERROR } from '../actions/auth';
import { SET_REVIEW_SENTIMENT_SUCCESS, SET_REVIEW_STATUS_SUCCESS, SET_DISPLAY_AD_STATUS_SUCCESS } from '../actions/account';

const processAccount = account => {
	return {
		...account,
		createdAt: account.createdAt && new Date(account.createdAt),
		updatedAt: account.updatedAt && new Date(account.updatedAt),
		reviewRespondedAt: account.reviewRespondedAt && new Date(account.reviewRespondedAt)
	};
};

const account = (state = null, action) => {
	switch (action.type) {
		case WHOAMI_SUCCESS:
			return processAccount(action.payload);
		case SET_REVIEW_SENTIMENT_SUCCESS:
			return { ...state, reviewSentiment: action.payload };
		case SET_REVIEW_STATUS_SUCCESS:
			return { ...state, reviewStatus: action.payload };
		case SET_DISPLAY_AD_STATUS_SUCCESS:
			return { ...state, displayAdStatus: action.payload };
		case WHOAMI_ERROR:
			return null;
		default:
			return state;
	}
};

const initialState = {
	isPerformingWhoAmI: false,
	loginFailureMessage: null
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case WHOAMI:
			return { ...state, isPerformingWhoAmI: true };
		case WHOAMI_SUCCESS:
			return { ...state, isPerformingWhoAmI: false };
		case WHOAMI_ERROR:
			return { ...state, loginFailureMessage: action.payload.error, isPerformingWhoAmI: false };
		default:
			return state;
	}
};

export default combineReducers({
	account,
	auth
});

export const isPerformingWhoAmI = state => state.auth.isPerformingWhoAmI;
export const getLoginFailureMessage = state => state.auth.loginFailureMessage;
export const getAccount = state => state.account;
